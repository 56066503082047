<template>
    <div v-if="!userId" class="lang_switcher">
        <div @click="toggleDropdown" class="current_lang">
            <span :class="'switcher lang_' + currentLang"></span>
            {{ $t('app.lang_' + currentLang) }}
        </div>
        <div v-if="isDropdownOpen" class="dropdown">
            <div class="dropdown_item" v-for="lang in languages" :key="lang.code" @click="changeLanguage(lang.code)">
                <span :class="'switcher lang_' + lang.code"></span>
                {{ $t('app.lang_' + lang.code) }}
            </div>
        </div>
    </div>
    <router-view v-if="!isLoading"/>
    <div id="infoTooltip" class="tooltip"></div>
    <ChatComponent v-if="!isLoading && userId" :user_id="userId" />
    <MessageBoard v-if="showMessageBoard" :message="message" @close="closeMessageBoard" />
</template>

<script>

import ChatComponent from '@/components/ChatComponent.vue';
import MessageBoard from '@/components/MessageBoard.vue';
import { usePiniaStore } from '@/store/store';

export default {
    data() {
        return {
            userId: null,
            isLoading: true,
            message: {},
            showMessageBoard: false,
            currentLang: 'pl',
            isDropdownOpen: false,
            languages: [
                { code: 'pl' },
                { code: 'en' },
                { code: 'de' }
            ]
        };
    },
    components: {
        ChatComponent,
        MessageBoard,
    },
    name: 'App',
    methods: {
        closeMessageBoard() {
            this.message = {};
            this.showMessageBoard = false;
        },
        close() {
            this.message = {};
        },
        async changeLanguage(lang) {
            console.log(`Zmiana języka na: ${lang}`);
            localStorage.setItem('langSwitcher', lang);
            this.isDropdownOpen = false;
            if (!this.store.langTranslations || this.$i18n.locale !== lang) {
                await this.store.fetchTranslations(lang).then(() => {
                    this.$i18n.setLocaleMessage(lang, this.store.langTranslations);
                    this.$i18n.locale = lang;
                });
            }
            document.documentElement.setAttribute('lang', lang);
            console.log(`Język ustawiony na: ${lang}`);
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
    },
    async mounted() {
        const browserLang = navigator.language.split('-')[0]; // Pobierz język przeglądarki

        const savedLang = localStorage.getItem('langSwitcher');
        this.isDropdownOpen = false;
        // Ustawienie języka na podstawie userData lub przeglądarki
        const currentLang = (this.store.userData && this.store.sUserLang ? this.store.sUserLang :  null) || savedLang || browserLang;

        // Ładujemy nowy język, tylko jeśli savedLang różni się od sUserLang
        if (this.userData && savedLang && savedLang !== this.store.sUserLang) {
            await this.store.fetchTranslations(savedLang).then(() => {
                this.$i18n.setLocaleMessage(savedLang, this.store.langTranslations);
                this.$i18n.locale = savedLang;
            });
        } else if (!this.userData || !savedLang) {
            // Pobierz tłumaczenia dla wybranego języka (sUserLang lub browserLang)
            await this.store.fetchTranslations(currentLang).then(() => {
                this.$i18n.setLocaleMessage(currentLang, this.store.langTranslations);
                this.$i18n.locale = currentLang;
            });
        }

        // Ustaw currentLang dla selecta
        this.currentLang = this.$i18n.locale;

        console.log(`Język ustawiony na: ${this.$i18n.locale}`);

        const userData = this.store.userData;
        if (userData) {
            console.log('userdata', userData);
            this.userId = userData.id;
            console.log(`Zalogowany użytkownik: ${userData.id}`);
        } else {
            this.userId = null;
            console.log("Użytkownik niezalogowany");
        }
        this.isLoading = false;
        await this.store.fetchGameData();
    },
    computed: {
        // eslint-disable-next-line vue/no-dupe-keys
        userId() {
            return this.store.getShowChat;
        }
    },
    created() {
        this.store = usePiniaStore();

        this.message = this.store.message;

        // Nasłuchuj na zmiany w wiadomości
        this.$watch(
            () => this.store.message,
            (newMessage) => {
                this.message = newMessage;
                this.showMessageBoard = true;
            }
        );
    },
    watch: {
        '$i18n.locale'(newLang) {
            // Kiedy zmienia się locale, ustaw atrybut lang
            document.documentElement.setAttribute('lang', newLang);
        }
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* Stylizacja dla głównego komponentu */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#app {
    height: 100%;
    width: 100%;
    text-align: center;
}

.tooltip {
    position: absolute;
    margin: 10px;
    padding: 10px;
    background: black;
    max-width: 300px;
    pointer-events: none;
    z-index: 15;
    color: #adff2f;
    font-family: Pirata One, cursive;
    background: rgba(0, 0, 0, .8);
    border: 2px solid #daa520;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color .3s, color .3s, transform .2s;
    text-shadow: 1px 1px 2px #000;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .5);
}

.tooltip.fade-in {
    opacity: 1;
    display: block;
}

.tooltip.fade-out {
    opacity: 0;
    display: none;
}

.chat-window {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.chat-messages {
    height: 90%;
    overflow-y: auto;
}

.message {
    margin-bottom: 5px;
}

input {
    width: 100%;
    box-sizing: border-box;
}

.lang_switcher {
    position: absolute;
    top:10px;
    right:10px;
    width: 110px;
    font-size: 13px;
    color: #adff2f;
    font-family: Pirata One, cursive;
    background: rgba(0, 0, 0, .6);
    border: 2px solid #daa520;
    border-radius: 5px;
    transition: background-color .3s, color .3s, transform .2s;
    text-shadow: 1px 1px 2px #000;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
}

.switcher {
    display: block;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 5px;
    cursor: pointer;
}

.dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 5px;
}

.dropdown_item {
    padding: 5px;
    cursor: pointer;
}

.current_lang {
    padding: 5px;
    cursor: pointer;
}

</style>
