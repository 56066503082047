<template>
    <div class="pagination">
        <button v-if="currentPage !== 1" @click="goToPage(currentPage - 1)">
            {{ $t('pagination.previous') }}
        </button>
        <span>{{ $t('pagination.page') }} {{ currentPage }} {{ $t('pagination.of') }} {{ totalPages }}</span>
        <button v-if="totalPages > currentPage" @click="goToPage(currentPage + 1)">
            {{ $t('pagination.next') }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        }
    },
    methods: {
        goToPage(page) {
            this.$emit('changePage', page);
        }
    }
}
</script>

<style scoped>
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>

