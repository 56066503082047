<template>
    <div class="map-grid">
        <TimerComponent v-if="timerActive" :endTime="initialTime" :action="timerAction"></TimerComponent>
        <div v-for="piece in mapPieces" :key="piece.piece_number" class="map-cell">
            <div v-if="piece.count > 0">
                <img :src="mapPieceImages[piece.piece_number]">
                <span class="piece-count" v-if="piece.count > 1">{{ piece.count }}</span>
            </div>
            <div v-else class="empty-map-piece"></div>
        </div>
        <button v-if="allPiecesCollected" @click="sendCrewForTreasure" class="treasure-button">{{ $t('app.wyslij_zaloge_po_skarb') }}</button>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import TimerComponent from '@/components/TimerComponent.vue';
import { usePiniaStore } from '@/store/store'; // Importuj swój store Pinia
import API from "@/utils/axios";

export default {
    name: 'TreasureMap',
    components: {
        TimerComponent
    },
    setup() {
        const store = usePiniaStore(); // Inicjalizacja store Pinia

        const initialTime = ref(120);
        const timerAction = ref(0);
        const timerActive = ref(false);

        const sendCrewForTreasure = () => {
            initialTime.value = 60; // Resetuje czas
            timerAction.value = 1; // Zmienia akcję
            timerActive.value = true; // Pokazujemy timer

            const data = {
                timeLeft: initialTime.value,
                action: timerAction.value
            };

            API.post(`/saveTime`, data)
                .then(response => {
                    console.log(response.data.message);
                    this.store.setMessage(response.data.message);
                }).catch(error => {
                console.error('Error saving march time:', error);
            });
        };

        // Zamiast mapState używamy computed, aby uzyskać dostęp do stanu Pinia
        const mapPieces = computed(() => store.mapPieces);

        // Computed do obliczenia stanu zebrania mapy
        const allPiecesCollected = computed(() => {
            return Object.values(mapPieces.value).every(piece => piece.count > 0);
        });

        const mapPieceImages = computed(() => {
            return Object.keys(mapPieces.value).reduce((acc, key) => {
                const piece = mapPieces.value[key];
                // eslint-disable-next-line no-undef
                acc[key] = require('@/assets/images/treasures_map/element_' + piece.piece_number + '.png');
                return acc;
            }, {});
        });

        // Przeniesienie mounted() do setup() z użyciem onMounted
        onMounted(() => {
            store.fetchMapPieces(); // Używamy akcji Pinia bezpośrednio

            const data = {
                action: 1
            };

            API.get(`/getTime`, data)
                .then(response => {
                    initialTime.value = response.data.timeLeft;
                    timerAction.value = 1;
                    timerActive.value = true;
                })
                .catch(error => {
                    console.error('Error fetching march time:', error);
                });
        });

        return {
            initialTime,
            timerAction,
            timerActive,
            sendCrewForTreasure,
            allPiecesCollected,
            mapPieceImages
        };
    }
}
</script>


<style scoped>
.map-grid {
    display: grid;
    grid-template-columns: repeat(4, 50px);
    grid-gap: 2px;
    width: 208px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.map-cell {
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-cell img {
    width: 100%;
    height: 100%;
}

.piece-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 2px 5px;
}

.empty-map-piece {
    width: 50px;
    height: 50px;
    background-color: #eee;
}

.treasure-button {
    grid-column: 1 / -1;
    margin-top: 20px;
}

</style>
