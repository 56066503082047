<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="fort_page">
        <div class="fort_area">
            <h3>{{ $t('app.armaty_na_murach_w_forcie') }} {{ cityName }}</h3>
            <div v-if="Object.keys(groupedGuns).length > 0">
                <div v-for="(gunType, index) in groupedGuns" :key="index">
                    <h3>{{ gunType.typeName }}</h3>
                    <div>
                        {{ gunType.count }} x {{ gunType.typeName }}: {{ $t('app.suma_mocy_armat') }} {{ gunType.totalDamage }}
                    </div>
                </div>
                <p>{{ $t('app.moc_wszystkich_armat') }}: {{ totalPowerGun }}</p>
            </div>
            <div v-else>
                <p>{{ $t('app.brak_armat_na_murach') }}.</p>
            </div>
            <div v-if="gunInShop.length > 0">
                {{ $t('app.armaty_w_sklepie') }}
                <li v-for="product in gunInShop" :key="product.id">
                    <input type="checkbox" v-model="selectedGuns[product.product_id]" :true-value="true" :false-value="false">
                    {{ product.productDetails.name }} - {{ product.quantity }} szt. -
                    <input type="number" v-model.number="selectedQuantities[product.product_id]" :max="product.quantity" min="0">
                </li>
                <button @click="executeTransfer">{{ $t('app.przenies_na_fort') }}</button>
            </div>
            <FactoryComponent @update-products="fetchData"></FactoryComponent>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
    </div>
</template>
<script>

import LoadingComponent from '@/components/LoadingComponent.vue';
import FactoryComponent from '@/components/FactoryComponent.vue';
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    name: "FortPage",
    components: {
        LoadingComponent,
        FactoryComponent
    },
    data() {
        return {
            tile_id: this.$route.params.tile_id,
            cityName: '',
            groupedGuns: {},
            totalPowerGun: 0,
            isLoading: true,
            gunInShop: [],
            selectedGuns: {},
            selectedQuantities: {}
        };
    },
    methods: {
        fetchData() {
            this.userData = this.store.getUserData;
            const cityData = this.userData.city;
            this.gunInShop = cityData.products.filter(product => product.product_type === 'gun' && product.quantity > 0) ?? [];
            this.cityName = cityData.name;
            this.groupedGuns = cityData.groupedGuns;
            this.totalPowerGun = cityData.totalPowerGun;
            this.isLoading = false;
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika do miasta
            this.$router.push('/city/' + tile_id);
        },
        addGunOnFort(gunData) {
            // dodajemy armaty na mury miasta
            API.post('/set-new-localization-for-gun', gunData)
                .then(response => {
                    const userData = response.data.userData;
                    this.store.setUserData(userData);
                    this.gunInShop = userData.city.products.filter(product => product.product_type === 'gun' && product.quantity > 0) ?? [];
                    this.groupedGuns = userData.ship.groupedGuns;
                    this.totalPowerGun = userData.ship.totalPowerGun;
                });
        },
        executeTransfer() {
            const gunsToTransfer = Object.keys(this.selectedGuns)
                .filter(id => this.selectedGuns[id] && this.selectedQuantities[id] > 0)
                .map(id => ({ id, quantity: this.selectedQuantities[id] }));

            if (gunsToTransfer.length > 0) {
                this.addGunOnFort({ guns: gunsToTransfer, localization: 0 });
            }
        }
    },
    mounted() {
        this.fetchData();
    },
    created() {
        this.store = usePiniaStore();
    }
}
</script>

<style scoped>

.fort_page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/fort_page.png') no-repeat center center fixed;
    background-size: cover;
}

.fort_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

.add_to_fort {
    margin-left: 10px;
}

</style>
