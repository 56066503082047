<template>
    <LoadingComponent :isLoading="isLoading"/>
    <UserInfo v-if="mapDataResponse"/>
    <centerOnTile v-if="mapDataResponse"
        @center-map="handleCenterMap"
        @center-island="handleCenterIsland"
        @center-ship="handleCenterShip">
    </centerOnTile>
    <div class="map-container" tabindex="0">
        <div v-if="mapDataResponse" class="map-area" :style="mapStyle" id="hexes" ref="hexes"
             @mousedown="startDrag"
             @mousemove="onDrag"
             @mouseup="stopDrag"
             @mouseleave="stopDrag"
             @touchstart.passive="startDrag"
             @touchmove.passive="onDrag"
             @touchend="stopDrag"
        >
            <div v-for="tile in mapDataResponse.tiles" :key="tile.num"
                :class="tile.class"
                :num="tile.num" :row="tile.row" :column="tile.column"
                @click="openModal(tile.num)"
                @touchend="handleClick(tile.num)"
                @mouseenter="showCoordinates(tile.row, tile.column)"
                @mouseleave="hideCoordinates"
                @touchstart.passive="handleTouchStart(tile.row, tile.column)"
                @touchcancel="handleTouchCancel"
                v-tooltip :tooltip="tile.description"
                :ref="`tile-${tile.num}`"
                >
            </div>
            <WaveComponent :position-x="positionX" :position-y="positionY" />
        </div>
    </div>
    <ModalComponent
        ref="modal"
        :modalData="modalData"
        :modalPosition="modalPosition"
        :isAlert="isAlert"
    />
    <NavbarArea
        :move-up="canMoveUp"
        :move-right="canMoveRight"
        :zoom="zoom"
        :move-down="canMoveDown"
        :move-left="canMoveLeft"
        @move="handleKey"
    />
    <PirateNotification />
    <div v-if="showingCoordinates" class="coordinates-display">
        {{ coordinates }}
    </div>
</template>

<script>

import NavbarArea from '@/components/NavbarArea.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import API from "@/utils/axios";
import {getToken} from "@/utils/utils";
import WaveComponent from '@/components/WaveComponent.vue';
import CenterOnTile from '@/components/CenterOnTile.vue';
import PirateNotification from '@/components/PirateNotification.vue';
import UserInfo from '@/components/UserInfo.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import {usePiniaStore} from "@/store/store";

export default {
    name: 'MapGrid',
    components: {
        NavbarArea,
        ModalComponent,
        WaveComponent,
        CenterOnTile,
        PirateNotification,
        UserInfo,
        LoadingComponent
    },
    data() {
        return {
            element: this.$route.query.center || null,
            totalRows: 54,
            totalColumns: 124,
            positionX: 0,
            positionY: 0,
            isDragging: false,
            hasDragged: false,
            startX: 0,
            startY: 0,
            zoom: 1,
            mapDataResponse: null,
            modalData: {},
            modalPosition: { top: 0, left: 0 },
            messages: [],
            isAlert: false,
            currentRow: null,
            currentColumn: null,
            tileSize: 50,
            step: 250,
            centerOnShip: false,
            isLoading: true,
            userData: {},
            showingCoordinates: false,
            coordinates: '',
        };
    },
    computed: {
        maxX() {
            // Maksymalne przesunięcie w prawo
            return -(this.tileSize / this.zoom) * this.totalColumns + (this.tileSize / this.zoom) * 25;
        },
        minX() {
            // Maksymalne przesunięcie w prawo
            return (this.tileSize / this.zoom) * 5;
        },
        maxY() {
            // Maksymalne przesunięcie w dół
            return -(this.tileSize / this.zoom) * this.totalRows + (this.tileSize / this.zoom) * 5;
        },
        minY() {
            // Maksymalne przesunięcie w górę
            return (this.tileSize / this.zoom) * 5;
        },
        mapStyle() {
            return {
                transform: `translate(${this.positionX}px, ${this.positionY}px) scale(${this.zoom})`,
                width: `calc(${(this.tileSize/this.zoom)}px * ${this.totalColumns})`,
            }
        },
        canMoveLeft() {
            return this.positionX < this.minX;
        },
        canMoveRight() {
            return this.positionX > this.maxX;
        },
        canMoveUp() {
            return this.positionY < this.minY;
        },
        canMoveDown() {
            return this.positionY > this.maxY;
        }
    },
    methods: {
        handleKey(event) {
            // Krok przesunięcia
            const stepMove = (this.step/this.zoom);
            switch (event) {
                case 'up':
                    if (this.canMoveUp) {
                        this.positionY += stepMove;
                    } else {
                        this.positionY += 10;
                        setTimeout(() => {
                            this.positionY -= 10;
                        }, 50);
                    }
                    break;
                case 'down':
                    if (this.canMoveDown) {
                        this.positionY -= stepMove;
                    } else {
                        this.positionY -= 10;
                        setTimeout(() => {
                            this.positionY += 10;
                        }, 50);
                    }
                    break;
                case 'left':
                    if (this.canMoveLeft) {
                        this.positionX += stepMove;
                    } else {
                        this.positionX += 10;
                        setTimeout(() => {
                            this.positionX -= 10;
                        }, 50);
                    }
                    break;
                case 'right':
                    if (this.canMoveRight) {
                        this.positionX -= stepMove;
                    } else {
                        this.positionX -= 10;
                        setTimeout(() => {
                            this.positionX += 10;
                        }, 50);
                    }
                    break;
                case 'zoom_in':
                    this.zoom += 1; // Przybliż
                    break;
                case 'zoom_out':
                    this.zoom -= 1; // Oddal
                    break;
            }
        },
        async openModal(tileId) {
            if (!this.hasDragged) {
                if (!this.isUserLoggedIn()) {
                    // Przekieruj użytkownika do formularza logowania
                    await this.$router.push('/login');
                    return;
                }
                await API.get(`/tile/${tileId}`)
                    .then(response => {
                        this.modalData = response.data.data;
                            const tileRef = this.$refs[`tile-${tileId}`];
                            if (tileRef) {
                                const { top, left } = tileRef[0].getBoundingClientRect();
                                this.modalPosition = {
                                    top: top + window.scrollY + 'px',
                                    left: left + window.scrollX + 'px'
                                };
                            }
                        this.$refs.modal.openModal();
                    })
                    .catch(error => {
                        console.error('Błąd podczas pobierania danych dla modala:', error);
                    });
            }
            this.hasDragged = false;
        },
        isUserLoggedIn() {
            // Sprawdź, czy token użytkownika jest dostępny w sessionStorage
            const token = getToken();
            // Zwróć true, jeśli token istnieje i jest niepusty
            return token && token !== '';
        },
        fetchMapData() {
            this.isLoading = true;
            API.get('/loadMap')
            .then(response => {
                this.mapDataResponse = response.data.mapData;
                switch (this.element) {
                    case 'ship':
                        this.handleCenterShip();
                        break;
                    case 'city':
                        this.handleCenterIsland();
                        break;
                    default:
                        // eslint-disable-next-line no-case-declarations
                        const coords = {
                            x: response.data.startPositionX,
                            y: response.data.startPositionY,
                        };
                        this.handleCenterMap(coords);
                        break;
                }
                // Usuń parametr query po centrowaniu
                this.$router.replace({ path: '/map', query: null });
                this.isLoading = false;
            }).catch(error => {
                console.error('Błąd podczas pobierania danych mapy:', error);
            });
        },
        updateShipsElements(elements) {

            if (!elements.ships || !Array.isArray(elements.ships)) {
                console.error('Invalid elements.ships data:', elements.ships);
                return;
            }

            this.userData = this.store.getUserData;
            const shipData = this.userData.ship;

            elements.ships.forEach(item => {
                const element = document.querySelector(`div[num="${item.id}"]`);
                if (element) {

                    // Usuwanie wszystkich klas
                    const regex = /^(ocean|island_|ship_|islandWithShip_)/;
                    element.classList.forEach(cls => {
                        if (regex.test(cls)) {
                            element.classList.remove(cls);
                        }
                    });
                    // Dodanie odpowiednich klas
                    element.classList.add(...item.class.split(' '));
                    element.setAttribute('tooltip', item.title);

                    if (shipData.id === item.ship_id) {
                        this.store.setShipData({ tile: { row: item.row, column: item.column, id: item.id} });
                        if (this.centerOnShip) {
                            const coords = {
                                x: item.row,
                                y: item.column,
                            };
                            this.handleCenterMap(coords);
                        }
                    }

                } else {
                    console.warn(`Element with id ${item.id} not found`); // Debug log
                }
            });
        },
        handleCenterMap(coords) {
            const pixelCoords = this.gridCoordsToPixelCoords(coords.x, coords.y);
            this.positionX = pixelCoords.x;
            this.positionY = pixelCoords.y;
        },
        handleCenterIsland() {
            this.userData = this.store.getUserData;
            const cityData = this.userData.city;
            this.centerOnShip = false;
            const coords = {
                x: cityData.tile.row,
                y: cityData.tile.column,
            };
            this.handleCenterMap(coords);
        },
        handleCenterShip() {
            this.userData = this.store.getUserData;
            const shipData = this.userData.ship;
            this.centerOnShip = true;
            const coords = {
                x: shipData.tile.row,
                y: shipData.tile.column
            };
            this.handleCenterMap(coords);
        },
        gridCoordsToPixelCoords(row, col) {
            const tileWidth = 50;
            const tileHeight = 50;
            const x = col * tileWidth + tileWidth / 2;
            const y = row * tileHeight + tileHeight / 2;

            // Obliczanie przesunięcia
            const newX = -(x - window.innerWidth / 2);
            const newY = -(y - window.innerHeight / 2);
            return { x: newX, y: newY };
        },
        showCoordinates(row, column) {
            this.coordinates = `${row} x ${column}`;
            this.showingCoordinates = true;
        },
        hideCoordinates() {
            this.showingCoordinates = false;
        },
        startDrag(event) {
            this.isDragging = true;
            this.hasDragged = false;
            this.startX = event.clientX;
            this.startY = event.clientY;
        },
        onDrag(event) {
            if (this.isDragging) {
                const deltaX = event.clientX - this.startX;
                const deltaY = event.clientY - this.startY;

                this.startX = event.clientX;
                this.startY = event.clientY;

                // Aktualizacja pozycji mapy
                this.positionX += deltaX;
                this.positionY += deltaY;
                this.hasDragged = true;
            }
        },
        stopDrag() {
            this.isDragging = false;
        },
        handleClick(tileNum) {
            // Obsługa tapnięcia jako kliknięcia
            if (!this.hasDragged) {
                this.openModal(tileNum);
            }
        },
        handleTouchStart(row, column) {
            // Rozpocznij odliczanie dla long press
            this.longPressTimeout = setTimeout(() => {
                this.showCoordinates(row, column); // Wywołanie funkcji long press
            }, 500); // 500ms to standardowy czas dla long press
        },
        handleTouchEnd() {
            // Anuluj long press po zakończeniu dotyku
            clearTimeout(this.longPressTimeout);
        },
        handleTouchCancel() {
            // Anuluj long press, jeśli dotyk został przerwany
            clearTimeout(this.longPressTimeout);
        }
    },
    created() {
        this.store = usePiniaStore();
        this.fetchMapData();
    },
    mounted() {
        this.$socket.on('dataUpdated', (data) => {
            console.log('Otrzymano aktualizację danych z serwera:', data);

            if (Array.isArray(data) && data.length > 0) {
                this.updateShipsElements({ ships: data });
            }
        });
    }
}
</script>

<style scoped>

.map-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%; /* Dostosuj do potrzeb */
    height: 100%; /* Dostosuj do potrzeb */
    background-color: blue;
}

.map-area:active {
    cursor: grabbing; /* Zmiana kursora na grab podczas przeciągania */
}

.map-area {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    transition: transform 0.3s ease; /* Płynne przesunięcie */
    z-index: 0;
    cursor: pointer;

}

.map-row {
    display: flex;
}

.tile {
    width: 50px; /* Szerokość kafelka */
    height: 50px; /* Wysokość kafelka */
    border: 1px solid rgba(204, 204, 204, 0.4);
    z-index: 2;
}

.islandWithShip_1_1 {
    background: url('@/assets/images/sprite.png') 0 -50px no-repeat;
}
.islandWithShip_1_2 {
    background: url('@/assets/images/sprite.png') 0 -100px no-repeat;
}
.islandWithShip_1_3 {
    background: url('@/assets/images/sprite.png') 0 -150px no-repeat;
}
.islandWithShip_1_4 {
    background: url('@/assets/images/sprite.png') 0 -200px no-repeat;
}
.islandWithShip_1_5 {
    background: url('@/assets/images/sprite.png') 0 -250px no-repeat;
}
.islandWithShip_1_6 {
    background: url('@/assets/images/sprite.png') 0 -300px no-repeat;
}

.islandWithShip_2_1 {
    background: url('@/assets/images/sprite.png') -50px -50px no-repeat;
}
.islandWithShip_2_2 {
    background: url('@/assets/images/sprite.png') -50px -100px no-repeat;
}
.islandWithShip_2_3 {
    background: url('@/assets/images/sprite.png') -50px -150px no-repeat;
}
.islandWithShip_2_4 {
    background: url('@/assets/images/sprite.png') -50px -200px no-repeat;
}
.islandWithShip_2_5 {
    background: url('@/assets/images/sprite.png') -50px -250px no-repeat;
}
.islandWithShip_2_6 {
    background: url('@/assets/images/sprite.png') -50px -300px no-repeat;
}

.islandWithShip_3_1 {
    background: url('@/assets/images/sprite.png') -100px -50px no-repeat;
}
.islandWithShip_3_2 {
    background: url('@/assets/images/sprite.png') -100px -100px no-repeat;
}
.islandWithShip_3_3 {
    background: url('@/assets/images/sprite.png') -100px -150px no-repeat;
}
.islandWithShip_3_4 {
    background: url('@/assets/images/sprite.png') -100px -200px no-repeat;
}
.islandWithShip_3_5 {
    background: url('@/assets/images/sprite.png') -100px -250px no-repeat;
}
.islandWithShip_3_6 {
    background: url('@/assets/images/sprite.png') -100px -300px no-repeat;
}

.ship_1 {
    background: url('@/assets/images/sprite.png') -150px -50px no-repeat;
}
.ship_2 {
    background: url('@/assets/images/sprite.png') -150px -100px no-repeat;
}
.ship_3 {
    background: url('@/assets/images/sprite.png') -150px -150px no-repeat;
}
.ship_4 {
    background: url('@/assets/images/sprite.png') -150px -200px no-repeat;
}
.ship_5 {
    background: url('@/assets/images/sprite.png') -150px -250px no-repeat;
}
.ship_6 {
    background: url('@/assets/images/sprite.png') -150px -300px no-repeat;
}

.island_1 {
    background: url('@/assets/images/sprite.png') 0 0 no-repeat;
}
.island_2 {
    background: url('@/assets/images/sprite.png') -50px 0 no-repeat;
}
.island_3 {
    background: url('@/assets/images/sprite.png') -100px 0 no-repeat;
}

.coordinates-display {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    z-index: 1000; /* Upewnij się, że jest na wierzchu */
}

</style>
