<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div id="stats_page">
        <div class="stats_area">
            <h1>{{ $t('app.pirackie_statystyki') }}</h1>
            <div v-if="selectedTab">
                <table class="stats_table">
                    <tbody>
                    <tr class="top">
                        <td class="top_left_corner"></td>
                        <td class="top_line" colspan="5"></td>
                        <td class="top_right_corner"></td>
                    </tr>
                    <tr>
                        <td  class="left_vertical_line"></td>
                        <td colspan="5">
                            <div class="tabs">
                                <button
                                    class="tab"
                                    v-for="(tab, index) in tabs"
                                    :key="index"
                                    @click="selectTab(tab.value)"
                                    v-tooltip :tooltip="$t('app.stats_' + index)"
                                    :class="{ active: selectedTab && selectedTab.value === tab.value }"
                                >
                                    {{ tab.name }}
                                </button>
                            </div>
                        </td>
                        <td class="right_vertical_line"></td>
                    </tr>
                    <tr>
                        <td  class="left_vertical_line margin-row"></td>
                        <td colspan="5" class="margin-row"></td>
                        <td class="right_vertical_line"></td>
                    </tr>
                    <tr>
                        <th class="left_vertical_line"></th>
                        <th>{{ $t('app.pozycja') }}</th>
                        <th>{{ $t('app.bractwo') }}</th>
                        <th>{{ $t('app.awatar') }}</th>
                        <th>{{ $t('app.gracz') }}</th>
                        <th>{{ $t('app.punkty') }}</th>
                        <th class="right_vertical_line"></th>
                    </tr>
                    <tr>
                        <td  class="left_vertical_line margin-row"></td>
                        <td colspan="5" class="margin-row"></td>
                        <td class="right_vertical_line"></td>
                    </tr>
                    <tr v-for="(user, index) in users.data" :key="user.user_id" :class="{ 'highlight': user.user_id === currentUserId }">
                        <td class="left_vertical_line"></td>
                        <td>{{ index + 1 + (currentPage - 1) * perPage }}</td>
                        <td>{{ user.clan }}</td>
                        <td>
                            <div :class="user.avatar_class"></div>
                        </td>
                        <td>{{ user.username }}</td>
                        <td>{{ user[selectedTab.value] }}</td>
                        <td class="right_vertical_line"></td>
                    </tr>
                    <tr class="bottom">
                        <td class="bottom_left_corner"></td>
                        <td class="bottom_line" colspan="5"></td>
                        <td class="bottom_right_corner"></td>
                    </tr>
                    </tbody>
                </table>
                <PaginationComponent
                    :currentPage="currentPage"
                    :totalPages="totalPages"
                    @changePage="handlePageChange"
                />
            </div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
    </div>
</template>

<script>

import API from "@/utils/axios";
import LoadingComponent from "@/components/LoadingComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";

export default {
    name: "StatsComponent",
    components: {
        LoadingComponent,
        PaginationComponent
    },
    data() {
        return {
            tile_id: null,
            currentUserId: null,
            selectedTab: null,
            users: [],
            isLoading: true,
            tabs: [
                { name: this.$t('app.ranking_ogolny'), value: 'total' },
                { name: this.$t('app.wladca_bitew'), value: 'battles' },
                { name: this.$t('app.handlowy_baron'), value: 'sales' },
                { name: this.$t('app.wladca_oceanow'), value: 'fields_travelled' },
                { name: this.$t('app.mistrz_artylerii'), value: 'battle_value' },
                { name: this.$t('app.technologiczny_wizjoner'), value: 'technology' }
            ],
            currentPage: 1,
            totalPages: 1,
            perPage: 20
        }
    },
    methods: {
        async selectTab(tabValue) {
            this.selectedTab = this.tabs.find(tab => tab.value === tabValue);
            this.currentPage = 1;
            await this.fetchStats(tabValue, this.currentPage);
        },
        async fetchStats(sortBy, page = 1) {
            API.get(`/getStats/${sortBy}`, {
                    params: {
                        page,
                        per_page: this.perPage
                    }
                })
                .then(response => {
                    this.users = response.data.usersStats;
                    this.currentUserId = response.data.userId;
                    this.tile_id = response.data.tile_id;
                    this.totalPages = response.data.usersStats.last_page;
                    this.isLoading =  false;
                })
                .catch(error => {
                    console.error('Error fetching stats:', error);
                });
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
                this.fetchStats(this.selectedTab.value, page);
            }
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        },
        handlePageChange(newPage) {
            this.changePage(newPage);
        },
    },
    mounted() {
        this.selectTab('total');
    }
}
</script>

<style>

.tabs {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 0 auto;
}

.stats_table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
}

.stats_table tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.tabs .active {
    color: #daa520;
}

.stats_table tr.highlight {
    font-weight: bold;
    background-color: #adff2f70;
    color: black;
}

.stats_area {
    position: absolute;
    left: 50%;
    top: 100px;
    width: 60%;
    height: 100%;
    color: greenyellow;
    font-family: 'Montserrat', sans-serif;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, .7);
}

.margin-row {
    height: 20px;
}

#stats_page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/stats_page.png') no-repeat center center fixed;
    background-size: cover;
}
.stats_table {
    width: 100%;
}

.top {
    height: 55px;
}

.left_vertical_line {
    background: url('@/assets/images/stats_table/left_vertical_line.png') repeat-y;
    width: 77px;
}

.top_left_corner {
    background: url('@/assets/images/stats_table/top_left_corner.png') no-repeat;
    width: 77px;
}

.top_line {
    background: url('@/assets/images/stats_table/top_line.png') repeat-x;
}

.top_right_corner {
    background: url('@/assets/images/stats_table/top_right_corner.png') no-repeat;
    width: 76px;
}

.right_vertical_line {
    background: url('@/assets/images/stats_table/right_vertical_line.png') repeat-y;
    width: 76px;
}

.bottom {
    height: 56px;
}

.bottom_left_corner {
    background: url('@/assets/images/stats_table/bottom_left_corner.png') no-repeat;
    width: 77px;
}

.bottom_line {
    background: url('@/assets/images/stats_table/bottom_line.png') repeat-x;
}

.bottom_right_corner {
    background: url('@/assets/images/stats_table/bottom_right_corner.png') no-repeat;
    width: 76px;
}

</style>
