<template>
    <div>
        <h2>Potwierdzenie adresu e-mail</h2>
        <p>{{ confirmationMessage }}</p>
    </div>
</template>

<script>
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    data() {
        return {
            confirmationMessage: ''
        };
    },
    created() {
        this.store = usePiniaStore();
    },
    mounted() {
        const token = this.$route.query.token;
        if (token) {
            this.confirmEmail(token);
        } else {
            console.error('Brak tokenu w adresie URL');
            this.confirmationMessage = 'Brak tokenu w adresie URL.';
        }
    },
    methods: {
        async confirmEmail(token) {
            await API.post(`/confirm-email`, { token })
            .then(response => {
                this.confirmationMessage = response.data.message;
                const authToken = response.data.token;
                if (authToken) {
                    // Zapisz token w local storage
                    sessionStorage.setItem('token', authToken);
                    this.store.setUserData(response.data.userData);
                    this.store.setShowChat(response.data.userData.id); // wysyłamy do App.js info aby pokazać chat
                    const sUserLang = response.data.userData.lang || 'pl';
                    if (sUserLang && sUserLang !== this.store.sUserLang) {
                        this.store.fetchTranslations(sUserLang).then(() => {
                            this.$i18n.setLocaleMessage(sUserLang, this.store.langTranslations);
                            this.$i18n.locale = sUserLang;
                        });
                    }
                    this.store.fetchTechnologyTree();
                    this.$router.push('/map');
                }
            })
            .catch(error => {
                console.error('Błąd podczas potwierdzania adresu e-mail:', error);
                if (error.response && error.response.data && error.response.data.error) {
                    this.confirmationMessage = error.response.data.error;
                } else {
                    this.confirmationMessage = 'Wystąpił błąd podczas potwierdzania adresu e-mail.';
                }
            });
        }
    }
};
</script>
