<template>
    <div id="messageArea" :class="[message.class, { hidden: isHidden }]">
        <div class="close_button" @click="closeMessage" v-tooltip :tooltip="$t('app.zamknij')">x</div>
        <p v-if="message">
            {{ message.description }}
        </p>
    </div>
</template>

<script>
export default {
    props: ['message'],
    data() {
        return {
            isHidden: false,
        };
    },
    methods: {
        closeMessage() {
            this.isHidden = true;
            this.$emit('close');
        },
    },
};
</script>

<style scoped>

#messageArea {
    position: fixed;
    bottom: 5px;
    right: 10px;
    padding: 10px 50px 10px 10px;
    font-size: 16px;
    opacity: 1;
    font-family: Pirata One, cursive;
    background: rgba(0, 0, 0, .6);
    border-radius: 5px;
    color: #adff2f;
    text-shadow: 1px 1px 2px #000;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    transition: background-color .3s, color .3s, transform .2s;
}

.success {
    background-color: rgba(0, 255, 0, 0.6); /* Zielony */
    border: 4px solid #c3e6cb;
}

.error {
    background-color: rgba(255, 0, 0, 0.6); /* Czerwony */
    border: 4px solid #f5c6cb;
}

.warning {
    background-color: rgba(255, 255, 0, 0.6); /* Żółty */
    border: 4px solid #ffeeba;
}

.info {
    background-color: rgba(0, 0, 255, 0.6); /* Niebieski */
    border: 4px solid #b8daff;
}

.hidden {
    display: none;
}
</style>
