<template>
  <LoadingComponent :isLoading="isLoading" />
  <div class="game_page">
    <div class="dice_area left_position">
      <div v-if="goldPool > 0" class="win_pool">
        <p>{{ $t('app.potencjalne_wygrane') }}:</p>
        <div>🏆 {{ $t('app.poker_opis_poker', { reward: pokerReward }) }}</div>
        <div>🎲 {{ $t('app.poker_opis_kareta', { reward: karetaReward }) }}</div>
        <div>🃏 {{ $t('app.poker_opis_full_house', { reward: fullHouseReward }) }}</div>
        <div>🎲 {{ $t('app.poker_opis_trojka', { reward: trojkaReward }) }}</div>
        <div>🎲 {{ $t('app.poker_opis_dwie_pary', { reward: dwieParyReward }) }}</div>
        <div>🎲 {{ $t('app.poker_opis_para', { reward: paraReward }) }}</div>
      </div>
    </div>

    <!-- Sekcja kości gracza i pirata -->
    <div class="final_game">
      <!-- Kości gracza -->
      <div v-if="playerDice.length" class="flex_47">
        <div class="message_area">{{ $t('app.wyrzuciles_kosci') }}:</div>
        <div class="dice_container">
          <div
            v-for="(die, index) in playerDice"
            :key="index"
            :class="getDiceClass(die, index)"
            @click="toggleSelectDie(index)"
          ></div>
        </div>
      </div>

      <!-- Kości pirata -->
      <div v-if="pirateDice.length" class="flex_47">
        <div class="message_area">{{ $t('app.pirat_wyrzucil_kosci') }}:</div>
        <div class="dice_container">
          <div
            v-for="(die, index) in pirateDice"
            :key="index"
            :class="[`dice dice_${die}`, { selected: pirateSelectedDice.includes(index) }]"
          ></div>
        </div>
      </div>

      <!-- Komunikaty o grze -->
      <div class="flex_100">
        <p v-if="message" class="message_area">{{ message }}</p>
      </div>
    </div>

    <!-- Sekcja przycisków akcji -->
    <div class="dice_area button_area">
      <p>{{ $t('app.twoje_zloto', { playerGold: playerGold }) }}<br></p>

      <!-- Przycisk do pierwszego rzutu -->
      <button v-if="!isSecondRoll" @click="rollDice" :disabled="isRolling">
        {{ $t('app.rzuc_kosci_za', { bet: bet }) }}!
      </button>

      <!-- Przycisk do przerzutu wybranych kości -->
      <button v-if="isSecondRoll" @click="rollSelectedDice" :disabled="isRolling">
        {{ $t('app.przerzuc_wybrane_kosci') }}
      </button>

      <!-- Przycisk do zakończenia tury bez przerzutu -->
      <button v-if="isSecondRoll" @click="endTurnWithoutReroll" :disabled="isRolling">
        {{ $t('app.nie_przerzucam') }}!
      </button>
    </div>

    <!-- Powrót do tawerny -->
    <div class="toTavern" @click="goToTavern(tile_id)">&larr; {{ $t('app.do_tawerny') }}</div>
  </div>
</template>

<script>
import API from "@/utils/axios";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "DicePage",
  components: {
    LoadingComponent
  },
  data() {
    return {
      tile_id: this.$route.params.tile_id,
      playerDice: [],
      pirateDice: [],
      bet: 0,
      message: '',
      reward: 0,
      playerGold: 0,
      isRolling: false,
      isSecondRoll: false,
      selectedDice: [], // inicjalizacja pustej tablicy dla zaznaczonych kości
      pirateSelectedDice: [], // inicjalizacja dla kości pirata
      cityName: '',
      goldPool: 0,
      isLoading: true
    };
  },
  methods: {
    goToTavern(tile_id) {
      this.$router.push('/tavern/' + tile_id);
    },
    async fetchCityDetails() {
      await API.get('city-details', {
        params: { tile_id: this.tile_id }
      })
        .then(response => {
          this.goldPool = response.data.goldPool;
          this.playerGold = response.data.playerGold;
          this.bet = response.data.bet;
        })
        .catch(error => {
          console.error('Błąd podczas pobierania informacji o mieście:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleSelectDie(index) {
      if (this.selectedDice.includes(index)) {
        this.selectedDice = this.selectedDice.filter(i => i !== index); // Odznaczanie
      } else {
        this.selectedDice.push(index); // Zaznaczanie
      }
    },
    getDiceClass(die, index) {
      // Tylko dla wartości kości 1-6
      if (die >= 1 && die <= 6) {
        return [`dice dice_${die}`, { selected: this.selectedDice.includes(index) }];
      }
      return '';
    },
    async rollDice() {
      const response = await API.post('/play-dice', { tile_id: this.tile_id, bet: this.bet });
      this.selectedDice = [];
      this.playerDice = response.data.playerDice;
      this.pirateDice = response.data.pirateDice;
      this.message = response.data.message;
      this.isSecondRoll = response.data.isSecondRoll;
      this.goldPool = response.data.newGoldPool;
      this.playerGold = response.data.newPlayerGold;
    },
    async rollSelectedDice() {
      this.isRolling = true;

      // Tylko zaznaczone kości są przerzucane, inne pozostają takie same
      const diceToReroll = this.playerDice.map((die, index) =>
        this.selectedDice.includes(index) ? null : die
      );

      await API.post('/roll-selected-dice', {
        tile_id: this.tile_id,
        playerDice: diceToReroll,  // Przerzucone kości gracza
        pirateDice: this.pirateDice,  // Kości pirata po pierwszym rzucie
        bet: this.bet // koszt rzutu kościami (w złocie)
      })
        .then(response => {
          this.playerDice = response.data.playerDice;
          this.pirateDice = response.data.pirateDice;
          this.message = response.data.message;
          this.goldPool = response.data.newGoldPool;
          this.playerGold = response.data.newPlayerGold;

          this.selectedDice = []; // Zresetowanie zaznaczenia kości po przerzucie
          this.isSecondRoll = false;  // Koniec drugiego rzutu
        })
        .catch(error => {
          this.message = error.response.data.message || 'Wystąpił błąd.';
        })
        .finally(() => {
          this.isRolling = false;
        });
    },
    async endTurnWithoutReroll() {
      this.isRolling = true;

      await API.post('/end-turn-without-reroll', {
        tile_id: this.tile_id,
        playerDice: this.playerDice,  // Kości gracza
        pirateDice: this.pirateDice,  // Kości pirata
        bet: this.bet // koszt rzutu kościami (w złocie)
      })
        .then(response => {
          this.pirateSelectedDice = response.data.pirateSelectedDice;  // Zaznaczone kości pirata do przerzutu
          this.playerDice = response.data.playerDice;
          this.pirateDice = response.data.pirateDice;
          this.message = response.data.message;
          this.goldPool = response.data.newGoldPool;
          this.playerGold = response.data.newPlayerGold;
          this.isSecondRoll = false;  // Zakończ drugi rzut
        })
        .catch(error => {
          this.message = error.response.data.message || 'Wystąpił błąd.';
        })
        .finally(() => {
          this.isRolling = false;
        });
    }
  },
  created() {
    this.fetchCityDetails();
  },
  computed: {
    pokerReward() {
      return this.goldPool;
    },
    karetaReward() {
      return Math.floor(this.goldPool * 0.50);
    },
    fullHouseReward() {
      return Math.floor(this.goldPool * 0.20);
    },
    trojkaReward() {
      return Math.floor(this.goldPool * 0.15);
    },
    dwieParyReward() {
      return Math.floor(this.goldPool * 0.12);
    },
    paraReward() {
      return Math.floor(this.goldPool * 0.10);
    }
  }
};
</script>

<style scoped>
.game_page {
  width: 100%;
  height: 100%;
  position: relative;
  background: url('@/assets/images/game_page.png') no-repeat center center fixed;
  background-size: cover;
}

.win_pool {
  flex: 0 0 50%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.dice_area {
  position: relative;
  background: rgba(0, 0, 0, .7);
  padding: 20px;
  width: 25%;
  color: #adff2f;
}

.flex_47 {
  flex: 0 0 47%;
}

.flex_100 {
  flex: 0 0 100%;
}

.message_area {
  background: rgba(0, 0, 0, .7);
  color: #adff2f;
  display: inline-block;
  padding: 5px;
}

.button_area {
  position: absolute;
  top: 0;
  right: 0;
}

.final_game {
  width: 50%;
  left: 50%;
  position: absolute;
  bottom: 12px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  transform: translateX(-50%);
}

.left_position {
  left: 180px;
}

.dice_container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 57%;
  margin: 0 auto;
  gap: 10px;
}

.dice {
  width: 50px;
  height: 50px;
  animation: roll 1s linear 1;
}

@keyframes roll {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(90deg) translateX(10px) translateY(10px); }
  50% { transform: rotate(180deg) translateX(-10px) translateY(-10px); }
  75% { transform: rotate(270deg) translateX(10px) translateY(10px); }
  100% { transform: rotate(360deg); }
}

.dice_1 {
  background: url('@/assets/images/dices/dice_1.png') no-repeat center center;
  background-size: cover;
}

.dice_2 {
  background: url('@/assets/images/dices/dice_2.png') no-repeat center center;
  background-size: cover;
}

.dice_3 {
  background: url('@/assets/images/dices/dice_3.png') no-repeat center center;
  background-size: cover;
}

.dice_4 {
  background: url('@/assets/images/dices/dice_4.png') no-repeat center center;
  background-size: cover;
}

.dice_5 {
  background: url('@/assets/images/dices/dice_5.png') no-repeat center center;
  background-size: cover;
}

.dice_6 {
  background: url('@/assets/images/dices/dice_6.png') no-repeat center center;
  background-size: cover;
}

.dice.selected {
  border: 2px solid yellow;
  transform: scale(1.1);
}
</style>
