<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="governor_page" :style="backgroundImageStyle">
        <div class="governor_area">
            <div v-if="userData.city && userData.city.tile && parseInt(tile_id) === userData.city.tile.id">
                <p v-html="$t('app.witaj_moj_dzielny_kapitanie')"></p>
            </div>
            <div v-if="freshCityData && userData.city">
                {{ $t('app.ludnosc') }}: {{ userData.city.citizens }} {{ $t('app.osob') }}.<br>
                {{ $t('app.pracujacy') }}: {{ userData.city.workingCitizens }} {{ $t('app.osob') }}.<br>
                {{ $t('app.poziom_bezpieczenstwa') }}: {{ freshCityData.safetyParameter }}<br>

                <div id="population">
                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ $t('app.poziom_bezpieczenstwa') }}</th>
                            <th>{{ $t('app.zywnosc') }}</th>
                            <th>{{ $t('app.trunki') }}</th>
                            <th>{{ $t('app.efekt') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ $t('app.godzinowy_przyrost_ludnosci') }}</td>
                            <td>
                                <div v-if="freshCityData.safetyParameter !== null" :style="newCitizensStyle(freshCityData.safetyParameter)"
                                     v-tooltip
                                     :tooltip="freshCityData.safetyParameter < 1 ? $t('app.populacja_spada_bezpieczenstwo_niskie') : $t('app.populacja_wzrasta_bezpieczenstwo_wysokie')">
                                    <span v-html="freshCityData.safetyIcon" v-if="freshCityData.safetyIcon"></span>
                                </div>
                            </td>
                            <td>
                                <div v-if="freshCityData.newCitizensInHourFood !== null" :style="newCitizensStyle(freshCityData.newCitizensInHourFood)"
                                     v-tooltip
                                     :tooltip="freshCityData.newCitizensInHourFood < 0 ? $t('app.populacja_spada_miasto_gloduje') : $t('app.tak_trzymac_jedzenia_mamy_pod_dostatkiem')">
                                    <span v-html="freshCityData.foodIcon" v-if="freshCityData.foodIcon"></span>
                                </div>
                            </td>
                            <td>
                                <div v-if="freshCityData.newCitizensInHourLiguor !== null" :style="newCitizensStyle(freshCityData.newCitizensInHourLiguor)"
                                     v-tooltip
                                     :tooltip="freshCityData.newCitizensInHourLiguor < 0 ? $t('app.populacja_spada_potrzebujemy_rumu') : $t('app.tak_trzymac_wina_i_rumu_mamy_pod_dostatkiem')">
                                    <span v-html="freshCityData.liguorIcon" v-if="freshCityData.liguorIcon"></span>
                                </div>
                            </td>
                            <td>
                                <div v-tooltip :tooltip="freshCityData.populationEffect ? $t('app.populacja_wzrasta', {actCitizens: freshCityData.actCitizens}) : $t('app.populacja_spada', {actCitizens: freshCityData.actCitizens})">
                                    {{ freshCityData.populationEffect ? '↑' : '↓' }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="freshCityData.kaper_list">

            </div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
    </div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    name: "GovernorPage",
    components: {
        LoadingComponent
    },
    data() {
        return {
            tile_id: this.$route.params.tile_id,
            isLoading: true,
            userData: {},
            freshCityData: {}
        };
    },
    methods: {
        fetchData() {
            this.userData = this.store.getUserData;
            this.getFreshCityData();
            this.isLoading = false;
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika do miasta
            this.$router.push('/city/' + tile_id);
        },
        getFreshCityData() {
            // pobieramy dane statystyczne miasta
            API.get('/get_city_data/' + this.tile_id)
                .then(response => {
                    this.freshCityData = response.data.data;
                });
        },
        newCitizensStyle(elementToStyle) {
            return {
                color: elementToStyle < 0 ? 'red' : 'green'
            };
        }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        backgroundImageStyle() {
            // Używamy require() do dynamicznego ładowania obrazów
            if (this.userData && this.userData.city && this.userData.city.icon) {
                // Używamy require() do dynamicznego ładowania obrazów
              // eslint-disable-next-line no-undef
                const imageUrl = require(`@/assets/images/governor_page_${this.userData.city.icon}.png`);
                return {
                    background: `url(${imageUrl}) no-repeat center center fixed`,
                    backgroundSize: 'cover'
                };
            }
        }
    },
    mounted() {
        this.fetchData();
    },
    created() {
        this.store = usePiniaStore();
    }
}
</script>

<style scoped>

.governor_page {
    width: 100%;
    height: 100%;
    position: relative;
}

.governor_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

</style>
