<template>
    <div class="settings_page">
        <div class="settings_area">
            <h2>{{ $t('app.twoj_avatar') }}</h2>
            <div class="button_area">
                <h3>{{ $t('app.wybierz_plec') }}</h3>
                <div class="button" @click="selectGender('male')">{{ $t('app.mezczyzna') }}</div>
                <div class="button" @click="selectGender('female')">{{ $t('app.kobieta') }}</div>
                <div class="button" @click="selectGender(null)">{{ $t('app.wszystkie') }}</div>
            </div>
            <div class="pirate-container">
                <div v-for="pirate in filteredPirates" :key="pirate" class="pirate-selection">
                    <div :class="['pirate_icon', pirate, { selected: pirate === selectedPirate }]" @click="selectPirate(pirate)"></div>
                </div>
            </div>

            <h2>{{ $t('app.wersja_jezykowa') }}</h2>
            <div class="language-container">
                <h3>{{ $t('app.wybierz_jezyk_gry') }}</h3>
                <div v-for="language in languages" :key="language" class="language-selection">
                    <div :class="['language_icon', language.class, { selected: language.code === selectedLanguage }]"
                         v-tooltip :tooltip="language.name"
                         @click="selectLanguage(language.code)">
                    </div>
                </div>
            </div>

            <button @click="saveSettings">{{ $t('app.zapisz') }}</button>
            <div v-if="message" :class="['alert', message.class]">{{ message.text }}</div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
    </div>
</template>

<script>
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    name: "SettingsPage",
    data() {
        return {
            pirates: [
                'm_face_1', 'w_face_1', 'm_face_2', 'w_face_2', 'm_face_3', 'w_face_3',
                'm_face_4', 'w_face_4', 'm_face_5', 'w_face_5', 'w_face_6', 'm_face_6',
                'm_face_7', 'w_face_7', 'w_face_8', 'm_face_8', 'm_face_9', 'w_face_9',
                'm_face_10', 'w_face_10'
            ],
            selectedGender: null,
            selectedPirate: null,
            selectedLanguage: null,
            languages: [
                { name: this.$t('app.polski'), code: 'pl', class: 'lang_pl' },
                { name: this.$t('app.niemiecki'), code: 'de', class: 'lang_de' },
                { name: this.$t('app.angielski'), code: 'en', class: 'lang_en' },
            ],
            message: {},
            tile_id: null
        };
    },
    watch: {
        userData: {
            immediate: true,
            handler(newUserData) {
                if (newUserData) {
                    this.selectedPirate = newUserData.avatar_class;
                    this.selectedLanguage = newUserData.lang;
                    this.tile_id = newUserData.tile_id;

                    if (this.selectedPirate && this.selectedPirate.startsWith('m_face')) {
                        this.selectedGender = 'male';
                    } else if (this.selectedPirate && this.selectedPirate.startsWith('w_face')) {
                        this.selectedGender = 'female';
                    } else {
                        this.selectedGender = null;
                    }
                }
            }
        }
    },
    computed: {
        userData() {
            const store = usePiniaStore();
            return store.getUserData;
        },
        filteredPirates() {
            if (!this.selectedGender) {
                return this.pirates;
            } else if (this.selectedGender === 'male') {
                return this.pirates.filter(pirate => pirate.startsWith('m_face'));
            } else if (this.selectedGender === 'female') {
                return this.pirates.filter(pirate => pirate.startsWith('w_face'));
            }
            return [];
        }
    },
    methods: {
        selectGender(gender) {
            this.selectedGender = gender;
        },
        selectPirate(pirate) {
            this.selectedPirate = pirate;
        },
        selectLanguage(code) {
            this.selectedLanguage = code;
        },
        saveSettings() {
            if (!this.selectedPirate || !this.selectedLanguage) {
                this.message = {
                    text: this.$t('app.prosze_wybrac_avatar_i_jezyk'),
                    class: 'error'
                };
                return;
            }

            this.message = null;
            const settings = {
                icon: this.selectedPirate,
                language: this.selectedLanguage,
            };
            API.post('/settings', settings)
                .then(response => {
                    const store = usePiniaStore();
                    store.setUserData({
                        avatar_class: this.selectedPirate,
                        lang: this.selectedLanguage
                    });
                    console.log('iusw settings lan ='+ this.selectedLanguage);
                    store.fetchTranslations(this.selectedLanguage).then(() => {
                        this.$i18n.setLocaleMessage(this.selectedLanguage, store.langTranslations);
                        this.$i18n.locale = this.selectedLanguage;
                        this.message = {
                            text: response.data.text,
                            class: 'info'
                        };
                    });
                })
                .catch(error => {
                    this.message = {
                        text: 'Wystąpił błąd podczas zapisywania ustawień.',
                        class: 'info'
                    };
                    console.error(error);
                });
        },
        goToCity(tile_id) {
            this.$router.push('/city/' + tile_id);
        }
    },
};
</script>


<style scoped>

.settings_page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/settings_page.png') no-repeat center center fixed;
    background-size: cover;
}

.button_area {
    display: flex;
    color: greenyellow;
    width: 80%;
    margin: 0 auto;
    gap: 20px;
}

.pirate-container, .language-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin: 0 auto;
    max-height: 60vh;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
}

.settings_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 60%;
    margin: 0 auto;

}

.pirate-selection, .language-selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pirate_icon {
    width: 150px;
    height: 150px;
    cursor: pointer;
    border: 2px solid transparent;
}

.language_icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 2px solid transparent;
}

.pirate_icon:hover, .language_icon:hover {
    border-color: gold;
}

.pirate_icon.selected, .language_icon.selected {
    border-color: #b87333;
}

</style>
