<template>
    <div class="bg">
        <div class="login-container">
            <router-link :to="{ path: '/' }" class="nav-link login" active-class="active">{{ $t('app.logowanie') }}</router-link>
            <router-link :to="{ path: '/register' }" class="nav-link register" active-class="active">{{ $t('app.rejestracja') }}</router-link>
            <form @submit.prevent="resetPassword">
                <div class="mb-4">
                    <input type="email" class="form-control" v-model="email" :placeholder="$t('app.email')">
                </div>
                <button type="submit" class="btn btn-primary w-100 my-3 shadow">{{ $t('app.resetuj_haslo') }}</button>
            </form>
        </div>
    </div>
</template>

<script>
import API from "@/utils/axios";

export default {
  data() {
    return {
      email: ''
    };
  },
  methods: {
    async resetPassword() {
        await API.post('/reset-password', {
            email: this.email
        }).then(response => {
            console.log('hasło się udało '+ response);
        })
        .catch(error => {
            console.error(error);
        });
    }
  }
}
</script>

<style scoped>
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}
h2 {
    margin: 0;
    font-size: 20px;
    color: black;
}
.bg {
    background-image: url(@/assets/images/background.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}


input[type="email"], input[type="text"], input[type="password"] {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 20px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #5c8a8a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #457575;
}
.error-message {
    color: red;
    font-weight: bold;
}

.login {
    float: left;
}
.register {
    float: right;
}
</style>
