<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="academy_page">
        <div class="technology-tree">
            <h1>{{ $t('app.drzewo_technologiczne') }}</h1>
            <div class="technologies">
                <div v-for="tech in technologies" :key="tech.id" class="technology-item">
                    <div :class="'technology_' + tech.id">
                        <span v-tooltip :tooltip="tech.description" class="technology_description">?</span><br>
                        <div class="technology_title">
                            {{ tech.name }} {{ tech.userTechnologyLevel }}
                        </div>
                        <div v-if="tech.id !== techId" class="technology_data">
                            <span v-tooltip :tooltip="`${$t('app.koszt_technologii')} ${tech.cost} ${$t('app.dublonow')}`">$</span>
                            <span v-tooltip :tooltip="`${$t('app.czas_badania')} ${tech.formattedTime}`">T</span><br>
                        </div>
                        <div v-if="tech.id === techId && timerActive" class="technology_timer">
                            {{ $t('app.trwa_badanie') }} {{ tech.userTechnologyLevel + 1 }}<br>
                            <TimerComponent :endTime="endTime" :oData="tech" :action="timerAction"></TimerComponent>
                        </div>
                        <div v-else class="technology_button">
                            <div v-if="tech.canUnlock" @click="unlockTechnology(tech.id)">
                                Odblokuj
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
    </div>
</template>

<script>
import API from "@/utils/axios";
import TimerComponent from "@/components/TimerComponent.vue";
import LoadingComponent from '@/components/LoadingComponent.vue';
import {usePiniaStore} from "@/store/store";

export default {
    name: "AcademyPage",
    components: {
        TimerComponent,
        LoadingComponent
    },
    data() {
        return {
            technologies: [],
            timerActive: false,
            endTime: 0,
            timerAction: '',
            technologyName: '',
            tech: null,
            techId: null,
            tile_id: this.$route.params.tile_id,
            isLoading: false
        };
    },
    created() {
        this.store = usePiniaStore();
        this.fetchTechnologies();
    },
    methods: {
        formatTime(seconds) {
            const days = Math.floor(seconds / (3600 * 24));
            const hours = Math.floor((seconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;

            if (days > 0) {
                return `${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else if (hours > 0) {
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else if (minutes > 0) {
                return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else {
                return `${secs.toString().padStart(2, '0')}s`;
            }
        },
        async fetchTechnologies() {
            this.isLoading = true;
            try {
                const response = await API.get('/technologies');

                this.technologies = response.data.technologies.map(tech => {
                    return {
                        ...tech,
                        formattedTime: this.formatTime(tech.time)
                    };
                });

                if (response.data.endTime) {
                    console.log('rozwijamy technologię ', response.data.technologyInResearch);
                    this.technologyName = response.data.technologyInResearch.technology_name;
                    console.log('endTime', response.data.endTime);
                    this.endTime = response.data.endTime;
                    this.timerAction = 'stopTechnology';
                    this.techId = response.data.technologyInResearch.id;
                    this.timerActive = true;
                }
            } catch (error) {
                console.error('Błąd podczas pobierania technologii:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async unlockTechnology(techId) {
            try {
                const response = await API.post('/technologies/unlock', { technology_id: techId });

                await this.fetchTechnologies();
                this.store.setTreasureData({ dublons: response.data.newDublons});
                this.endTime = response.data.endTime;
                this.timerAction = 'stopTechnology';
                this.techId = techId;
                this.timerActive = true;
            } catch (error) {
                console.error('Błąd podczas odblokowywania technologii:', error);
            }
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        }
    }
}
</script>

<style scoped>

.technology-tree {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.technologies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.technology-item {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 20%;
    height:250px;
    position: relative;
    text-align: center;
    color: greenyellow;
    font-family: 'Pirata One', cursive;
}

.technology_1 {
    background: url(@/assets/images/technology_1.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_2 {
    background: url(@/assets/images/technology_2.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_3 {
    background: url(@/assets/images/technology_3.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_4 {
    background: url(@/assets/images/technology_4.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_5 {
    background: url(@/assets/images/technology_5.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_6 {
    background: url(@/assets/images/technology_6.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_7 {
    background: url(@/assets/images/technology_7.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_8 {
    background: url(@/assets/images/technology_8.png) no-repeat top center #000022;
    width: 100%;
    height: 100%;
    background-size: 100% 80%;
}

.technology_timer {
    position: absolute;
    bottom: 1px;
    width: 100%;
}

.technology_button {
    top: 74%;
    width: 25%;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.technology_description {
    position:absolute;
    right: 10px;
}

.technology_title {
    position: absolute;
    bottom: 52px;
    width: 100%;
}

.technology_data {
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: right;
}

.technology_data span {
    margin-right: 6px;
    cursor: pointer;
}

.academy_page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/academy_page.png') no-repeat center center fixed;
    background-size: cover;
    justify-content: space-between;
}

</style>
