<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="city-page">
        <UserInfo/>
        <!-- Interaktywne obszary -->
        <div v-if="shopIn" v-tooltip :tooltip="$t('app.sklep_i_magazyny')" class="area shop" @click="goToShopPage(tile_id)"></div>
        <div v-if="governorIn" v-tooltip :tooltip="$t('app.siedziba_gubernatora_wyspy')" class="area governor" @click="goToGovernorPage(tile_id)"></div>
        <div v-if="productionIn" v-tooltip :tooltip="$t('app.produkcja')" class="area production" @click="goToProductionPage(tile_id)"></div>
        <div v-if="fortIn" v-tooltip :tooltip="$t('app.mury_obrona_miasta')" class="area fort" @click="goToFortPage(tile_id)"></div>
        <div v-if="tavernIn" v-tooltip :tooltip="$t('app.tawerna_zapraszamy')" class="area tavern" @click="goToTavernPage(tile_id)"></div>
        <div v-if="academyIn" v-tooltip :tooltip="$t('app.akademia_rozwijaj_technologie')" class="area academy" @click="goToAcademyPage(tile_id)"></div>
        <div class="area map" v-tooltip :tooltip="$t('app.mapa')" @click="goToMap"></div>
        <div v-if="hasShipInPort" v-tooltip :tooltip="$t('app.twoj_statek_w_porcie')" class="area ship" :class="'ship_' + fleet_id" @click="goToShipPage(tile_id)"></div>
        <ModalComponent
            ref="modal"
            :modalData="modalData"
            :modalPosition="modalPosition"
            :isAlert="isAlert"
        />
    </div>
</template>

<script>

import UserInfo from '@/components/UserInfo.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import API from "@/utils/axios";
import LoadingComponent from '@/components/LoadingComponent.vue'

export default {
    name: 'CityPage',
    components: {
        UserInfo,
        ModalComponent,
        LoadingComponent
    },
    data() {
        return {
            hasShipInPort: false,
            shopIn: false,
            governorIn: false,
            productionIn: false,
            fortIn: false,
            tavernIn: false,
            academyIn: false,
            fleet_id: null,
            tile_id: this.$route.params.tile_id,
            modalData: null,
            modalPosition: { top: 0, left: 0 },
            messages: [],
            isAlert: false,
            isLoading: false
        };
    },
    mounted() {
        this.checkCity();
        this.checkShipInPort();
    },
    methods: {
        async checkCity() {
            this.isLoading = true;
            await API.get(`/city/${this.$route.params.tile_id}`)
            .then(response => {
                this.shopIn = response.data.links.shop;
                this.governorIn = response.data.links.governor;
                this.productionIn = response.data.links.production;
                this.fortIn = response.data.links.fort;
                this.tavernIn = response.data.links.tavern;
                this.academyIn = response.data.links.academy;
                this.isLoading = false;
            })
            .catch(error => {
                console.error('Błąd podczas sprawdzania statku w porcie:', error);
            });

        },
        async checkShipInPort() {
            await API.get(`/ship`, {
                params: {
                    tile_id: this.tile_id
                }
            })
            .then(response => {
                this.hasShipInPort = response.data.hasShipInPort;
                this.fleet_id = response.data.fleet_id;
            })
            .catch(error => {
                    console.error('Błąd podczas sprawdzania statku w porcie:', error);
            });
        },
        // Metoda przekierowująca do strony sklepu
        goToShopPage(tile_id) {
            // Przekieruj użytkownika do strony sklepu
            this.$router.push('/shop/'+ tile_id);
        },
        // Metoda przekierowująca do strony gubernatora
        goToGovernorPage(tile_id) {
            // Przekieruj użytkownika do strony gubernatora
            this.$router.push('/governor/'+ tile_id);
        },
        goToProductionPage(tile_id) {
            // Przekieruj użytkownika do strony produkcji
            this.$router.push('/production/'+ tile_id);
        },
        goToFortPage(tile_id) {
            // Przekieruj użytkownika na mury obronne
            this.$router.push('/fort/'+ tile_id);
        },
        goToShipPage(tile_id) {
            // Przekieruj użytkownika na mury obronne
            this.$router.push('/ship/'+ tile_id);
        },
        goToTavernPage(tile_id) {
            // Przekieruj użytkownika na mury obronne
            this.$router.push('/tavern/'+ tile_id);
        },
        goToAcademyPage(tile_id) {
            // Przekieruj użytkownika do akademii
            this.$router.push('/academy/'+ tile_id);
        },
        goToMap() {
            // Przekieruj użytkownika na mapę
            this.$router.push('/map');
        }
    }
};
</script>

<style scoped>

.city-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/city.png') no-repeat center center fixed;
    background-size: cover;
}

.area {
    position: absolute;
    cursor: pointer;
    transform: translate(-50%, -50%); /* Przesunięcie obszarów do środka ich kontenera */
}

.shop {
    top: 62%;
    left: 57%;
    width: 6%;
    height: 15%;
    background: url('@/assets/images/shop_island.png') no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.governor {
    top: 62%;
    left: 50%;
    width: 4%;
    height: 9%;
    z-index: 1;
    cursor: pointer;
}

.production {
    top: 88%;
    left: 75%;
    width: 38%;
    height: 23%;
    z-index: 1;
    cursor: pointer;
}

.fort {
    top: 30%;
    left: 50%;
    width: 6%;
    height: 26%;
    cursor: pointer;
}

.tavern {
    top: 72%;
    left: 41%;
    width: 10%;
    height: 15%;
    background: url('@/assets/images/tavern_island.png') no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.academy {
    top: 68%;
    left: 11%;
    width: 10%;
    height: 22%;
    background: url('@/assets/images/academy_island.png') no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.ship_1 {
    background: url('@/assets/images/ship_in_port.png') no-repeat center center;
    background-size: cover;
    top: 61%;
    right: 9%;
    width: 6%;
    height: 24%;
    cursor: pointer;
}

img {
    width: 50px;
    height: 50px;
}

.map {
    top: 73%;
    left: 86%;
    width: 28%;
    height: 7%;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .shop {
        top: 62%;
        left: 26%;
        width: 23%;
        height: 9%;
    }

    .governor {
        top: 40%;
        left: 47%;
        width: 11%;
        height: 9%;
        z-index:1;
    }

    .production {
        top: 49%;
        left: 86%;
        width: 26%;
        height: 14%;
    }

    .fort {
        top: 41%;
        left: 50%;
        width: 100%;
        height: 5%;
    }

    .tavern {
        top: 51%;
        left: 39%;
        width: 14%;
        height: 6%;
    }

    .ship {
        bottom: 5%;
        right: 5%;
        width: 15%;
        height: 8%;
    }

    .map {
        bottom: -10%;
        right: 0;
        width: 22%;
        height: 23%;
    }
}



</style>
