<template>
    <div class="bg">
        <div class="login-container">
            <router-link :to="{ path: '/' }" class="nav-link login" active-class="active">{{ $t('app.logowanie') }}</router-link>
            <router-link :to="{ path: '/register' }" class="nav-link register" active-class="active">{{ $t('app.rejestracja') }}</router-link>
            <form @submit.prevent="loginUser">
                <input type="email" class="form-control" v-model="email" :placeholder="$t('app.podaj_email')">
                <input type="password" class="form-control" v-model="password" :placeholder="$t('app.podaj_haslo')">
                <button type="submit" class="btn btn-primary w-100 my-3 shadow">{{ $t('app.zaloguj') }}</button><br>
                <router-link to="/reset-password" class="float-end">{{ $t('app.przypomnienie_hasla') }}</router-link>
            </form>
        </div>
        <div v-if="errors.length" class="error-messages">
            <div v-for="(error, index) in errors" :key="index" class="error">{{ error }}</div>
        </div>
    </div>
</template>

<script>
import API from "@/utils/axios";
import { useHead } from '@vueuse/head';
import {usePiniaStore} from "@/store/store";

export default {
    data() {
        return {
            email: '',
            password: '',
            errors: []
        };
    },
    created() {
        this.store = usePiniaStore();
        if (this.isUserLoggedIn()) {
            this.$router.push('/map');
        }
    },
    methods: {
        isUserLoggedIn() {
            const token = sessionStorage.getItem('token');
            return token && token !== '';
        },
        async loginUser() {
            this.errors = [];
            try {
                const response = await API.post(`/login`, {
                    email: this.email,
                    password: this.password
                });

                const token = response.data.token;
                sessionStorage.setItem('token', token);

                const userData = response.data.userData;
                this.store.setUserData(userData);
                if (userData.lang) {
                    await this.store.fetchTranslations(userData.lang).then(() => {
                        this.$i18n.setLocaleMessage(userData.lang, this.store.langTranslations);
                        this.$i18n.locale = userData.lang;
                    });
                }
                await this.store.fetchTechnologyTree();
                this.store.setShowChat(userData.id);

                this.$router.push('/map');
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error) {
                    this.errors = [error.response.data.error];
                } else {
                    this.errors = ['Wystąpił nieoczekiwany błąd'];
                }
            }
        },
        updateMetaTags() {
            useHead({
                title: this.$t('app.title_loginForm'),
                meta: [
                    {
                        name: 'description',
                        content: this.$t('app.meta_description_loginForm'),
                    },
                    {
                        name: 'keywords',
                        content: this.$t('app.meta_keywords_loginForm'),
                    },
                ],
            });
        }
    },
    async mounted() {
        this.updateMetaTags();
    },
    watch: {
        '$i18n.locale'() {
            this.updateMetaTags();
        }
    }
};
</script>

<style scoped>
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

h2 {
    margin: 0;
    font-size: 20px;
    color: black;
}

.bg {
    background-image: url(@/assets/images/background.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

input[type="email"], input[type="password"] {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 20px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #5c8a8a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #457575;
}

.login {
    float: left;
}

.register {
    float: right;
}

.error-messages {
    color: red;
    background-color: #f8d7da;
    width: 100%;
    border: 1px solid #f5c6cb;
    position: fixed;
}

.error {
    margin: 5px 0;
}
</style>
