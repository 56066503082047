<template>
    <div class="pirate_dialogue">
        <h2>{{ $t('app.rozmowa_z_piratem') }}</h2>
        <p v-html="message.text"></p>
        <div v-if="responseType === 'continue'">
            <p>{{ $t('app.prosze_sprobuj_ponownie_lub_zadaj_inne_pytanie') }}.</p>
        </div>
        <div v-if="responseType === 'finished'">
            <p>{{ $t('app.dziekujemy_za_rozmowe') }}!</p>
        </div>
        <div v-if="responseType === 'finished' && message.isCorrect">
            <p v-html="responseText"></p><br>
            <TreasureMap/>
            <button v-if="buttonVisible" @click="handleCollectMapPiece">{{ $t('app.pobierz_fragment_mapy') }}</button>
        </div>
        <div v-if="message.question">
            <p>Pytanie: {{ message.question }}</p>
            <button v-for="answer in message.answers" :key="answer" @click="sendAnswer(answer.id, message.question_id)">
                {{ answer.content }}
            </button>
        </div>
        <button v-for="button in message.buttons" :key="button" @click="handleButton(button)">
            {{ formatButtonLabel(button) }}
        </button>
    </div>
</template>

<script>

import TreasureMap from "@/components/TreasureMap.vue";
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    props: ['pirateId'],
    data() {
        return {
            message: {},
            responseType: '',
            responseText: '',
            buttonVisible: true
        };
    },
    components: {
        TreasureMap,
    },
    watch: {
        pirateId(newPirateId, oldPirateId) {
            if (newPirateId !== oldPirateId) {
                this.interactWithPirate(newPirateId);
            }
        }
    },
    methods: {
        handleCollectMapPiece() {
            this.buttonVisible = false;
            this.responseText = this.$t('app.no_to_losujemy');
            this.store.collectMapPiece()
            .then(() => {
                this.responseText = this.$t('app.wylosowany_fragment_mapy_dodano_do_twojej_kolekcji');
            }).catch(error => {
                this.buttonVisible = true;
                console.error('Failed to collect map piece:', error);
                // Obsługa błędu, np. wyświetlenie komunikatu dla użytkownika
            });
        },
        async interactWithPirate(pirateId) {
            await API.get(`/pirate/${pirateId}/interact`)
            .then(response => {
                this.message = response.data.message;
            }).catch(error => {
                console.error('Error interacting with pirate:', error);
                this.message = this.$t('app.wystapil_blad_podczas_interakcji_z_piratem');
                this.store.setMessage(this.message);
            });
        },
        async sendAnswer(answerId, questionId) {
            await API.post(`/question/${questionId}/answer`, {
                answer_id: answerId,
            })
            .then(response => {
                this.message.text = response.data.message;
                this.responseType = response.data.isCorrect ? 'finished' : 'continue';
                this.message.question = '';
                this.message.isCorrect = response.data.isCorrect;
            }).catch(error => {
                console.error('Error sending answer:', error);
                this.message = this.$t('app.wystapil_blad_podczas_wysylania_wiadomosci');
                this.store.setMessage(this.message);
            });
        },
        handleButton(button) {
            if (button === 'end') {
                this.endDialogue();
            } else if (button === 'hire') {
                this.hirePirate(this.pirateId);
            }
        },
        formatButtonLabel(button) {
            if (button === 'end' || button === 'not_interested') {
                return this.$t('app.zakoncz_rozmowe');
            } else if (button === 'hire') {
                return this.$t('app.zatrudnij_pirata');
            }
        },
        hirePirate(pirateId) {
            API.post(`/pirate/${pirateId}/assign`)
                .then(response => {
                    this.message.text = response.data.message;
                    this.responseType = 'finished';
                    this.message.buttons = {};
                    setTimeout(() => {
                        this.$emit('end-dialogue');
                    }, 2000);
                })
                .catch(error => {
                    console.error('Błąd przy zatrudnianiu pirata:', error);
                    this.message = this.$t('app.nie_udalo_sie_zatrudnic_pirata');
                });
        },
        endDialogue() {
            this.message = {};
            this.responseType = 'finished';

            // Opóźnienie ukrycia komponentu o 2 sekundy
            setTimeout(() => {
                this.$emit('end-dialogue');
            }, 2000);
        }
    },
    created() {
        this.store = usePiniaStore();
    },
    mounted() {
        this.interactWithPirate(this.pirateId);
        this.responseText = this.$t('app.swietnie_tak_jak_obiecalem_mam_dla_ciebie_fragment_mapy');
    }
};
</script>

<style scoped>
.pirate_dialogue {
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    z-index: 1;
    color: greenyellow;
    background: rgba(0, 0, 0, .7);
}

button {
    margin: 5px;
}
</style>
