<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="tavern_page">
        <div v-for="(pirate, index) in pirates" :key="pirate.id" :class="'pirate-icon-' + (index + 1)" @click="initiateDialogue(pirate.id)">
        </div>
        <pirate-dialogue v-if="isDialogueOpen && currentPirateId" :pirateId="currentPirateId" @end-dialogue="handleEndDialogue"></pirate-dialogue>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
        <div class="toGame" @click="goToGame(tile_id)">{{ $t('app.zagraj_w_kosci') }} &rarr;</div>
    </div>
</template>

<script>
import PirateDialogue from '@/components/PirateDialogue.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import {mapState} from "vuex";
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    components: {
        PirateDialogue,
        LoadingComponent
    },
    data() {
        return {
            pirates: [],
            currentPirateId: null,
            isDialogueOpen: false,
            isLoading: true,
            tile_id: this.$route.params.tile_id
        };
    },
    computed: {
        // eslint-disable-next-line vue/no-dupe-keys
        isDialogueOpen() {
            return this.store.isDialogueOpen;
        },
    },
    methods: {
        fetchPirates(tile_id) {
            this.isLoading = true;
            API.get(`/pirate`, {
                params: {
                    tile_id: tile_id  // Dodanie city_id jako parametru zapytania
                }
            })
            .then(response => {
                this.pirates = response.data.pirates;
            }).catch(error => {
                console.error('Error fetching pirates:', error);
            }).finally(() => {
                this.isLoading = false; // Zakończenie ładowania
            });
        },
        initiateDialogue(pirateId) {
            this.currentPirateId = pirateId;
            this.isDialogueOpen = true;
        },
        handleEndDialogue() {
            this.isDialogueOpen = false;
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        },
        goToGame(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/game/'+ tile_id);
        }
    },
    created() {
        this.store = usePiniaStore();
        this.fetchPirates(this.tile_id);
    }
};
</script>

<style scoped>


.tavern_page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/tavern.png') no-repeat center center fixed;
    background-size: cover;
}

.pirate-icon-1 {
    top: 204px;
    width: 261px;
    height: 347px;
    position: absolute;
    left: 89px;
    background: url('@/assets/images/pirate_1.png') no-repeat center center;
}

.pirate-icon-2 {
    top: 197px;
    width: 236px;
    height: 309px;
    position: absolute;
    left: 285px;
    background: url('@/assets/images/pirate_2.png') no-repeat center center;
}

.pirate-icon-3 {
    top: 244px;
    width: 185px;
    height: 231px;
    position: absolute;
    left: 489px;
    background: url('@/assets/images/pirate_3.png') no-repeat center center;
}

.pirate-icon-4 {
    top: 263px;
    width: 154px;
    height: 188px;
    position: absolute;
    left: 666px;
    background: url('@/assets/images/pirate_4.png') no-repeat center center;
}

.pirate-icon-5 {
    top: 234px;
    width: 154px;
    height: 197px;
    position: absolute;
    left: 726px;
    background: url('@/assets/images/pirate_5.png') no-repeat center center;
}

.pirate-icon-6 {
    top: 268px;
    width: 112px;
    height: 138px;
    position: absolute;
    left: 810px;
    background: url('@/assets/images/pirate_6.png') no-repeat center center;
}

.pirate-icon-7 {
    top: 223px;
    width: 175px;
    height: 265px;
    position: absolute;
    left: 896px;
    background: url('@/assets/images/pirate_7.png') no-repeat center center;
}

.pirate-icon-8 {
    top: 265px;
    width: 130px;
    height: 219px;
    position: absolute;
    left: 1060px;
    background: url('@/assets/images/pirate_8.png') no-repeat center center;
}
</style>
